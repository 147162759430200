import {
  DataSnapshot,
  get,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  remove,
  set,
} from "@firebase/database";
import { store, unsynced } from "utils/storage";
import { PROJECT_ID } from "utils/constants";
import type {
  SkippedQuestionIdsType,
  SkippedQuestionIdType} from "utils/types";
import {
  SkippedQuestionId,
  SkippedQuestionIds
} from "utils/types";
import { path } from "./firebase";
import { isOffline } from "utils/offline";

type SkipQuestionArgs = { uid: string; yearKey: string; questionId: string };
type SkippedQuestionsArgs =
  | { key: string }
  | { uid: string; yearKey: string }
  | SkipQuestionArgs;

export const getSkippedQuestionsKey = (args: SkippedQuestionsArgs) => {
  if ("key" in args) {
    return args.key;
  }

  const base = `/users/${args.uid}/${PROJECT_ID}/${args.yearKey}/skipped`;
  if ("questionId" in args && !!args.questionId) {
    return `${base}/${args.questionId}`;
  }
  return base;
};

export const loadSkippedQuestionIds = async (
  args: SkippedQuestionsArgs,
  offlineValue: SkippedQuestionIdsType
): Promise<SkippedQuestionIdsType> => {
  if (isOffline()) {
    return offlineValue;
  }

  return get(path(getSkippedQuestionsKey(args))).then(
    validateSkippedQuestionIds
  );
};

export const unskipQuestion = async (args: SkipQuestionArgs) => {
  const key = getSkippedQuestionsKey(args);
  await store.removeItem(key);
  return unsynced.transact(
    remove(path(key)).then(() => true),
    {
      localforageKey: key,
      lastModifiedPath: key,
      timestamp: Date.now(),
      firebasePath: key,
      message: "Unskipped question",
    },
    true
  );
};

export const skipQuestion = async (
  args: SkipQuestionArgs,
  duration: number
) => {
  const skippedUntil: SkippedQuestionIdType = Date.now() + duration;
  const key = getSkippedQuestionsKey(args);
  const data = await store.setItem(key, skippedUntil);
  const res = await unsynced.transact(
    set(path(key), data).then(() => true),
    {
      localforageKey: key,
      lastModifiedPath: key,
      timestamp: Date.now(),
      firebasePath: key,
      message: "Skipped question",
    },
    true
  );
  return res;
};

export const onSkippedQuestionIdsChanged = (
  args: SkippedQuestionsArgs,
  {
    changed: onSkipChanged,
    removed: onSkipRemoved,
  }: {
    changed: (
      changedSkippedQuestionId: SkippedQuestionIdType,
      questionId: string
    ) => void;
    removed: (questionId: string) => void;
  }
) => {
  const key = getSkippedQuestionsKey(args);
  const firebaseRef = path(key);

  const unsubs = [
    onChildAdded(firebaseRef, (snap) => {
      if (!snap.key) {
        return;
      }
      onSkipChanged(validateSkippedQuestionId(snap), snap.key);
    }),
    onChildChanged(firebaseRef, (snap) => {
      if (!snap.key) {
        return;
      }
      onSkipChanged(validateSkippedQuestionId(snap), snap.key);
    }),
    onChildRemoved(firebaseRef, (snap) => {
      if (!snap.key) {
        return;
      }
      onSkipRemoved(snap.key);
    }),
  ];

  return () => unsubs.forEach((unsub) => unsub());
};

export const validateSkippedQuestionId = (
  obj: DataSnapshot | unknown
): SkippedQuestionIdType => {
  if (obj instanceof DataSnapshot) {
    if (!obj.exists()) {
      return 0;
    }
    return validateSkippedQuestionId(obj.val());
  }

  if (!obj) {
    return 0;
  }

  const skipped = SkippedQuestionId(obj);
  if (skipped.type !== "success") {
    throw new Error(`Failed to validate SkippedQuestionId object`);
  }
  return skipped.value;
};

export const validateSkippedQuestionIds = (
  obj: DataSnapshot | unknown,
  message: string = "Unable to validate SkippedQuestionIds"
): SkippedQuestionIdsType => {
  if (obj instanceof DataSnapshot) {
    if (!obj.exists()) {
      return {};
    }

    return validateSkippedQuestionIds(obj.val(), message);
  }

  if (obj === null) {
    return {};
  }

  const skipped = SkippedQuestionIds(obj);
  if (skipped.type !== "success") {
    throw new Error(`${message}: ${skipped.path}`);
  }
  return skipped.value;
};
