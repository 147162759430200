import type {
  CheckerReturnType} from "@recoiljs/refine";
import {
  number,
  object,
  optional,
  string,
  union,
} from "@recoiljs/refine";
import { get } from "firebase/database";
import { PROJECT_ID, YEAR_KEY } from "utils/constants";
import { path } from "./firebase";

export class OfflineError extends Error {}

export const DirtyFirebaseRecord = object({
  localforageKey: string(),
  lastModifiedPath: string(),
  timestamp: number(),
  firebasePath: string(),
  message: optional(string()),
});
export type DirtyFirebaseRecordType = CheckerReturnType<
  typeof DirtyFirebaseRecord
>;

export const DirtyFileRecord = object({
  localforageKey: string(),
  timestamp: number(),
  storageKey: string(),
  message: optional(string()),
});
export type DirtyFileRecordType = CheckerReturnType<typeof DirtyFileRecord>;

export const DirtyRecord2 = union(DirtyFirebaseRecord, DirtyFileRecord);
export type DirtyRecordType2 = CheckerReturnType<typeof DirtyRecord2>;

export const isDirtyFirebaseRecord = (
  record: DirtyRecordType2
): record is DirtyFirebaseRecordType =>
  (record as DirtyFirebaseRecordType).firebasePath !== undefined;

export const isDirtyFileRecord = (
  record: DirtyRecordType2
): record is DirtyFileRecordType =>
  (record as DirtyFileRecordType).storageKey !== undefined;

const lastModifiedRef = (key: string) => path(`/lastModified/${key}`);

const flattened = (
  baseKey: string,
  lastModifiedRecord: object
): Record<string, number> => {
  return Object.entries(lastModifiedRecord).reduce((acc, [key, value]) => {
    if (typeof value === "object" && value !== null) {
      const nested = flattened(`${baseKey}/${key}`, value);
      return { ...acc, ...nested };
    }

    return { ...acc, [`${baseKey}/${key}`]: value };
  }, {});
};

export const getLastModifiedRecord = async (
  uid: string
): Promise<Record<string, number>> => {
  const key = `/users/${uid}/${PROJECT_ID}/${YEAR_KEY}`;
  const lastModifiedSnap = await get(lastModifiedRef(key));
  const lastModifiedRecord = lastModifiedSnap.exists()
    ? lastModifiedSnap.val()
    : {};
  return flattened(key, lastModifiedRecord);
};
