import { captureMessage } from "@sentry/react";
import dayjs from "dayjs";
import { atom, selector, useRecoilValue } from "recoil";
import { YEAR_KEY } from "utils/constants";
import type {
  QuestionType,
  SheetsBackfillType,
  SheetsQuestionsType,
  SheetsQueueType,
} from "utils/types";
import { stillSkippedQuestionIds } from "./skipped";

export const sheetsQuestionsState = atom<SheetsQuestionsType>({
  key: "sheetsQuestionsState",
  default: {},
});

export const sheetsBackfillState = atom<SheetsBackfillType>({
  key: "sheetsBackfillState",
  default: [],
});

export const sheetsUserQueueState = atom<SheetsQueueType>({
  key: "sheetsUserQueueState",
  default: {},
});

const getWeekKey = (date: Date = new Date()) => {
  return dayjs(date).startOf("w").format("YYYYMMDD");
};

const getPreviousWeekKeys = (date: Date = new Date()) => {
  let current = new Date(date.getTime());
  const out = [];
  while (current.getFullYear() === +YEAR_KEY) {
    out.push(getWeekKey(current));
    current = new Date(current.getTime() - 7 * 24 * 60 * 60 * 1000);
  }
  return out;
};

export const sheetsCurrentQuestion = selector<QuestionType>({
  key: "sheetsCurrentQuestion",
  get: ({ get }) => {
    const backfill = get(sheetsBackfillState);
    const pool = get(sheetsQuestionsState);
    const userQueue = get(sheetsUserQueueState);
    const skipped = get(stillSkippedQuestionIds);

    const key = getWeekKey();
    let questionId = userQueue[key];

    const cleanedBackfill = backfill.filter((id) => {
      if (skipped[id]) {
        return false;
      }

      if (Object.values(userQueue).includes(id)) {
        return false;
      }

      return true;
    });

    if (cleanedBackfill.length === 0) {
      // We don't have any questions left in the backfill, so we need to
      // override the user's previous skips.
      cleanedBackfill.push(
        ...Object.entries(skipped)
          .filter(([, skippedUntil]) => skippedUntil > 0)
          .sort(([, a], [, b]) => a - b)
          .map(([id]) => id)
      );
    }

    if (cleanedBackfill.length === 0) {
      // If it's _still_ empty, then we need to look at the ones that the user
      // has said that they don't want to answer. At this point, it's just too
      // bad for them.
      cleanedBackfill.push(
        ...Object.entries(skipped)
          .sort(([, a], [, b]) => a - b)
          .map(([id]) => id)
      );
    }

    if (!questionId || skipped[questionId]) {
      questionId = cleanedBackfill[0];
    }

    const question = pool[questionId];
    if (!question) {
      captureMessage("Missing question");
      throw new Error("Missing question");
    }
    return question;
  },
});

export const usePreviousQuestions = (
  incomingDate?: Date
): QuestionType["id"][] => {
  // FIXME: This is a total hack and will need to be fixed if we want to do
  //        another round of this project.
  const date =
    incomingDate ??
    (() => {
      const now = new Date();
      if (now.getFullYear() > 2023) {
        return new Date(2023, 11, 31, 23, 59, 59);
      }
      return now;
    })();
  const backfill = useRecoilValue(sheetsBackfillState);
  const userQueue = useRecoilValue(sheetsUserQueueState);
  const skipped = useRecoilValue(stillSkippedQuestionIds);

  const cleanedBackfill = backfill.filter((id) => {
    if (skipped[id]) {
      return false;
    }

    if (Object.values(userQueue).includes(id)) {
      return false;
    }

    return true;
  });

  if (cleanedBackfill.length === 0) {
    // We don't have any questions left in the backfill, so we need to
    // override the user's previous skips.
    cleanedBackfill.push(
      ...Object.entries(skipped)
        .filter(([, skippedUntil]) => skippedUntil > 0)
        .sort(([, a], [, b]) => a - b)
        .map(([id]) => id)
    );
  }

  if (cleanedBackfill.length === 0) {
    // If it's _still_ empty, then we need to look at the ones that the user
    // has said that they don't want to answer. At this point, it's just too
    // bad for them.
    cleanedBackfill.push(
      ...Object.entries(skipped)
        .sort(([, a], [, b]) => a - b)
        .map(([id]) => id)
    );
  }

  return getPreviousWeekKeys(date).map((weekKey) => {
    let questionId = userQueue[weekKey];

    if (!questionId || skipped[questionId]) {
      questionId = cleanedBackfill[0];
    }

    return questionId;
  });
};

export const useCurrentQuestion = () => {
  return useRecoilValue(sheetsCurrentQuestion);
};

export const useQuestion = (id: string): QuestionType | undefined => {
  return useRecoilValue(sheetsQuestionsState)[id];
};
