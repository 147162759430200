import { captureMessage } from "@sentry/react";
import { usePreviousQuestions, useQuestion } from "App/DataProvider/sheets";
import {
  useSkippedQuestions,
  useUnskipQuestion,
} from "App/DataProvider/skipped";
import type { QuestionType } from "utils/types";
import { MdRestore } from "react-icons/md";
import classes from "./SkippedQuestionsList.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import type { ReactNode } from "react";
import { useAnsweredQuestionIds } from "App/DataProvider/answers";

type QuestionViewProps = Pick<QuestionType, "id"> & { canUnskip: boolean };

const QuestionView = ({ id, canUnskip }: QuestionViewProps) => {
  const question = useQuestion(id);
  const unskip = useUnskipQuestion(id);

  if (!question) {
    captureMessage(`Missing question: ${id}`);
    return null;
  }

  return (
    <div className={classes.question}>
      <div>
        <Link to={`/${question.id}`}>{question.question}</Link>
      </div>
      {canUnskip && (
        <button onClick={unskip}>
          <MdRestore />
          &nbsp;add this back in the queue
        </button>
      )}
    </div>
  );
};

export const SkippedQuestionsList = () => {
  const skippedQuestions = useSkippedQuestions();
  const answeredQuestionIds = useAnsweredQuestionIds();
  const answeredMap = answeredQuestionIds.reduce<
    Record<QuestionType["id"], true>
  >((acc, id) => ({ ...acc, [id]: true }), {});
  const previous = usePreviousQuestions();

  const [postponed, removed] = Object.entries(skippedQuestions).reduce(
    (acc, [questionId, untilMillis]) => {
      if (untilMillis <= 0) {
        return [acc[0], [...acc[1], questionId]];
      }
      return [[...acc[0], questionId], acc[1]];
    },
    [[], []] as [string[], string[]]
  );

  const sections: [string, ReactNode][] = [];
  if (previous.length > 0) {
    sections.push([
      "previous",
      <>
        <h1>Previous questions</h1>
        <ul>
          {previous.map((questionId) => (
            <li
              key={questionId}
              className={
                answeredMap[questionId] ? classes.answered : classes.pending
              }
            >
              <QuestionView id={questionId} canUnskip={false} />
            </li>
          ))}
        </ul>
      </>,
    ]);
  }

  if (postponed.length > 0) {
    sections.push([
      "postponed",
      <>
        <h1>Postponed questions</h1>
        <ul>
          {postponed.map((questionId) => (
            <li key={questionId}>
              <QuestionView id={questionId} canUnskip />
            </li>
          ))}
        </ul>
      </>,
    ]);
  }

  if (removed.length > 0) {
    sections.push([
      "removed",
      <>
        <h1>Removed questions</h1>
        <ul>
          {removed.map((questionId) => (
            <li key={questionId}>
              <QuestionView id={questionId} canUnskip />
            </li>
          ))}
        </ul>
      </>,
    ]);
  }

  return (
    <>
      <Helmet>
        <title>Atlantis - History</title>
      </Helmet>
      <div className={classes.container}>
        {sections.map(([key, section], i) => (
          <div key={key}>
            {i > 0 && <hr />}
            {section}
          </div>
        ))}
      </div>
    </>
  );
};
