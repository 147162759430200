import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

const formatDate = (date: Dayjs) => {
  return date.format("MM/DD");
};

export const currentWeek = () => {
  const start = dayjs().startOf("w");
  const end = dayjs().endOf("w");

  return [start, end].map((d) => formatDate(d));
};
