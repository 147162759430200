import "./Loading.css";
import classes from "./Loading.module.css";

type Props = {
  message: string;
};

export const Loading = ({ message }: Props) => {
  return (
    <div className={classes.container}>
      <div className="lds-dual-ring"></div>
      <p>{message}</p>
    </div>
  );
};
