// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import type {
  DatabaseReference} from "firebase/database";
import {
  child,
  getDatabase,
  ref,
  update,
} from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBvP-Dqcvavf3KS1FxiQkQjDXYiEXD0NCs",
  authDomain: "atlantis-88ddb.firebaseapp.com",
  projectId: "atlantis-88ddb",
  storageBucket: "atlantis-88ddb.appspot.com",
  messagingSenderId: "718007420823",
  appId: "1:718007420823:web:d6b54e3b614b209230e6d2",
  measurementId: "G-XG127G8B35",
};

let _app: ReturnType<typeof initializeApp> | undefined = undefined;
let _analytics: ReturnType<typeof getAnalytics> | undefined = undefined;
let _auth: ReturnType<typeof getAuth> | undefined = undefined;
let _db: ReturnType<typeof getDatabase> | undefined = undefined;
let _storage: ReturnType<typeof getStorage> | undefined = undefined;

export const app = (() => {
  if (!_app) {
    _app = initializeApp(firebaseConfig);
  }
  return _app;
})();

export const analytics = (() => {
  if (!_analytics) {
    _analytics = getAnalytics(app);
  }
  return _analytics;
})();

export const auth = (() => {
  if (!_auth) {
    _auth = getAuth(app);
  }
  return _auth;
})();

export const db = (() => {
  if (!_db) {
    _db = getDatabase(app);
  }
  return _db;
})();

export const storage = (() => {
  if (!_storage) {
    _storage = getStorage(app);
  }
  return _storage;
})();

export const path = (p: string) => {
  const dbRef = p
    .split("/")
    .filter((segment) => !!segment)
    .reduce((acc, part) => {
      if (!acc) {
        return ref(db, part);
      }
      return child(acc, part);
    }, null as DatabaseReference | null);
  if (!dbRef) {
    throw new Error("Invalid path");
  }
  return dbRef;
};

export const updateData = (key: string, data: object) => {
  return update(path(key), data);
};
