import React, {
  useCallback,
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import classes from "./Toaster.module.css";

type Props = {
  children: JSX.Element;
};

const ToasterContext = createContext<(text: string, millis?: number) => void>(
  () => {
    throw new Error("Rendered outside of a context");
  }
);

export const useToaster = () => {
  return useContext(ToasterContext);
};

export const Toaster = ({ children }: Props) => {
  const [toast, setToast] = useState<string | null>(null);

  const toastUi = useMemo(() => {
    if (!toast) {
      return null;
    }

    return (
      <div className={classes.container}>
        <div className={classes.toast}>
          <span className={classes.text}>{toast}</span>
        </div>
      </div>
    );
  }, [toast]);

  const timeoutRef = useRef<number | undefined | NodeJS.Timeout | null>(null);

  const open = useCallback((text: string, millis: number = 1000) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    setToast(text);
    timeoutRef.current = setTimeout(() => {
      setToast(null);
    }, millis);
  }, []);

  return (
    <ToasterContext.Provider value={open}>
      {children}
      {toastUi}
    </ToasterContext.Provider>
  );
};
