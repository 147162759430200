import { useEffect, useState } from "react";

export const isOffline = () => {
  return !window.navigator.onLine;
};

export const useOffline = () => {
  const [offline, setOffline] = useState(!window.navigator.onLine);

  useEffect(() => {
    const handler = () => setOffline(!window.navigator.onLine);
    window.addEventListener("offline", handler);
    window.addEventListener("online", handler);
    return () => {
      window.removeEventListener("offline", handler);
      window.removeEventListener("online", handler);
    };
  }, []);

  return { offline, online: !offline };
};
