import { Loading } from "components/Loading";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { SkippedQuestionsList } from "./SkippedQuestionsList";

const LazyAnswerCurrentQuestion = lazy(
  () => import("./AnswerQuestion/LazyCurrentQuestion")
);

const LazySpecificQuestion = lazy(
  () => import("./AnswerQuestion/LazySpecificQuestion")
);

const LazyPublishedAnswer = lazy(() => import("./PublishedAnswer"));

export const User = () => {
  return (
    <Suspense fallback={<Loading message="Loading ..." />}>
      <Routes>
        <Route path="/" element={<LazyAnswerCurrentQuestion />} />
        <Route path="/skipped" element={<SkippedQuestionsList />} />
        <Route path="/history" element={<SkippedQuestionsList />} />
        <Route path="/:questionId" element={<LazySpecificQuestion />} />
        <Route
          path="/:questionId/:otherUid"
          element={<LazyPublishedAnswer />}
        />
      </Routes>
    </Suspense>
  );
};
