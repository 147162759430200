import { Route, Routes } from "react-router-dom";
import { Page } from "components/Page";
import { Helmet } from "react-helmet";
import { User } from "./User";
import { Modal } from "./Modal";
import { Toaster } from "./Toaster";
import { Help } from "./Help";
import { RecoilRoot } from "recoil";
import { lazy, Suspense } from "react";
import { Loading } from "components/Loading";

const LazyDataProvider = lazy(() => import("./DataProvider"));

export const App = () => {
  return (
    <Modal>
      <Toaster>
        <Page>
          <Helmet>
            <title>Atlantis</title>
          </Helmet>
          <RecoilRoot>
            <Suspense fallback={<Loading message="Loading ..." />}>
              <LazyDataProvider>
                <Routes>
                  <Route path="/help" element={<Help />} />
                  <Route path="/*" element={<User />} />
                  <Route path="*" element={<div>404</div>} />
                </Routes>
              </LazyDataProvider>
            </Suspense>
          </RecoilRoot>
        </Page>
      </Toaster>
    </Modal>
  );
};
