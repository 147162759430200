import type {
  CheckerReturnType} from "@recoiljs/refine";
import {
  array,
  bool,
  dict,
  number,
  object,
  optional,
  or,
  string,
} from "@recoiljs/refine";

const uuid = string(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

type ModificationRecord = {
  createdAt: string;
  modifiedAt: string;
};

export const User = object({
  uid: string(),
  email: string(),
  displayName: optional(string()),
});

export type UserType = CheckerReturnType<typeof User>;

export type FirebaseQuestion = {
  question: string;
  subtext: string;
} & ModificationRecord;

export const Question = object({
  id: string(),
  question: string(),
  subtext: optional(string()),
  sortKey: optional(string()),
});

export const SkippedQuestionId = number();
export type SkippedQuestionIdType = CheckerReturnType<typeof SkippedQuestionId>;

export const SkippedQuestionIds = dict(SkippedQuestionId);
export type SkippedQuestionIdsType = CheckerReturnType<
  typeof SkippedQuestionIds
>;

export type QuestionType = CheckerReturnType<typeof Question>;

export const Questions = dict(Question);

export type QuestionsType = CheckerReturnType<typeof Questions>;

export type NewQuestion = Omit<QuestionType, "id" | keyof ModificationRecord>;

export type AnsweredQuestion = {
  id: string;
  questionId: string;
} & ModificationRecord;

export type OnSubmit = Required<JSX.IntrinsicElements["form"]>["onSubmit"];

export const never = (v: never) => {
  throw new Error("Illegal state: " + v);
};

export const TextContent = object({
  uuid,
  markdown: string(),
});

export type TextContentType = CheckerReturnType<typeof TextContent>;

export const PictureContent = object({
  uuid,
  caption: string(),
  uploaded: optional(bool()),
});

export type PictureContentType = CheckerReturnType<typeof PictureContent>;

export const Content = or(TextContent, PictureContent);

export type ContentType = CheckerReturnType<typeof Content>;

export const Answer = object({
  order: array(string()),
  items: dict(Content),
});
export type AnswerType = CheckerReturnType<typeof Answer>;

export const Answers = dict(Answer);
export type AnswersType = CheckerReturnType<typeof Answers>;

export const isTextContent = (
  content: ContentType
): content is TextContentType => {
  return (content as TextContentType).markdown !== undefined;
};

export const isPictureContent = (
  content: ContentType
): content is PictureContentType => {
  return (content as PictureContentType).caption !== undefined;
};

export const SheetsQuestions = dict(Question);
export type SheetsQuestionsType = CheckerReturnType<typeof SheetsQuestions>;

export const SheetsBackfill = array(string());
export type SheetsBackfillType = CheckerReturnType<typeof SheetsBackfill>;

export const SheetsQueue = dict(string());
export type SheetsQueueType = CheckerReturnType<typeof SheetsQueue>;

export const SheetsData = object({
  queue: SheetsQueue,
  backfill: SheetsBackfill,
  pool: SheetsQuestions,
});
export type SheetsDataType = CheckerReturnType<typeof SheetsData>;

export const validateSheetsData = (data: unknown) => {
  const sheets = SheetsData(data);
  if (sheets.type !== "success") {
    throw new Error("Invalid sheets data");
  }
  return sheets.value;
};

export class ImpossibleError extends Error {
  constructor(value: never) {
    super("Impossible situation");
    this.name = "ImpossibleError";
  }
}
