import { useCallback } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { YEAR_KEY } from "utils/constants";
import { skipQuestion } from "utils/firebase";
import { unskipQuestion } from "utils/firebase/skipped";
import type { SkippedQuestionIdsType } from "utils/types";
import { useUid } from "./uid";

export const skippedQuestionIds = atom<SkippedQuestionIdsType>({
  key: "skippedQuestionIdsState",
  default: {},
});

export const useSkippedQuestions = () => {
  return useRecoilValue(skippedQuestionIds);
};

export const stillSkippedQuestionIds = selector<SkippedQuestionIdsType>({
  key: "stillSkippedQuestionIds",
  get: ({ get }) => {
    const skippedIds = get(skippedQuestionIds);
    const now = Date.now();

    return Object.entries(skippedIds)
      .filter(([_questionId, untilMillis]) => {
        if (untilMillis <= 0) {
          return true;
        }

        if (now < untilMillis) {
          return true;
        }

        return false;
      })
      .reduce((acc, [id, skipUntil]) => ({ ...acc, [id]: skipUntil }), {});
  },
});

export const useSetSkippedQuestionIds = () => {
  return useSetRecoilState(skippedQuestionIds);
};

export const useUnskipQuestion = (id: string) => {
  const uid = useUid();
  const setSkippedQuestionIds = useSetSkippedQuestionIds();

  return useCallback(() => {
    setSkippedQuestionIds((old) => {
      const { [id]: _removed, ...rest } = old;
      return rest;
    });
    return unskipQuestion({ uid, yearKey: YEAR_KEY, questionId: id });
  }, [id, setSkippedQuestionIds, uid]);
};

export const useSkipQuestion = (
  id: keyof SkippedQuestionIdsType,
  durationMillis: number
) => {
  const uid = useUid();
  const setSkippedQuestionIds = useSetSkippedQuestionIds();

  return useCallback(() => {
    setSkippedQuestionIds((old) => ({
      ...old,
      [id]: Date.now() + durationMillis,
    }));
    return skipQuestion(
      { uid, yearKey: YEAR_KEY, questionId: String(id) },
      durationMillis
    );
  }, [durationMillis, id, setSkippedQuestionIds, uid]);
};
