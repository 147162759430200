import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import type { UserType } from "utils/types";

export const userState = atom<UserType>({
  key: "userState",
  default: {
    uid: "",
    email: "",
    displayName: "",
  },
});

export const useUid = () => {
  return useRecoilValue(userState).uid;
};

export const useUser = () => {
  return useRecoilValue(userState);
};

export const useSetUser = () => {
  return useSetRecoilState(userState);
};
