import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { unsynced } from "utils/storage";
import type { QuestionType } from "utils/types";
import { storage } from "./firebase";
import { isOffline } from "utils/offline";

type ServingArgs = {
  uid: string;
  questionId: QuestionType["id"];
  uuid: string;
};

type PictureArgs = ServingArgs | { key: string };

export const getPictureKey = (args: PictureArgs): string => {
  if ("key" in args) {
    return args.key;
  }

  const keys = getServingKeys(args);
  return keys[keys.length - 1];
};

const getServingKeys = (args: ServingArgs): [string] | [string, string] => {
  const unsized = `/uploads/${args.uid}/${args.questionId}/${args.uuid}`;
  // if ("width" in args && args.width) {
  //   return [
  //     `/uploads/${args.uid}/${args.questionId}/resized/${args.uuid}_${args.width}`,
  //     unsized,
  //   ];
  // }
  return [unsized];
};

const getPictureRef = (args: PictureArgs) => ref(storage, getPictureKey(args));

export const uploadPicture = async (
  args: PictureArgs,
  file: Parameters<typeof uploadBytes>[1],
  name?: string
): Promise<string> => {
  const key = getPictureKey(args);
  return unsynced.transact<string>(
    uploadBytes(getPictureRef({ key }), file).then((res) =>
      getDownloadURL(res.ref)
    ),
    {
      localforageKey: key,
      timestamp: Date.now(),
      storageKey: key,
      message: name ?? key,
    },
    URL.createObjectURL(file as Blob)
  );
};

export const getPictureUrl = async (args: PictureArgs) => {
  if (isOffline()) {
    return "";
  }

  const ref = getPictureRef(args);
  try {
    await getMetadata(ref);
    const url = await getDownloadURL(ref);
    return url;
  } catch (ex) {
    return "";
  }
};
