import { Link } from "react-router-dom";
import { MdHelpOutline, MdMenu } from "react-icons/md";
import classes from "./Header.module.css";
import { currentWeek } from "utils/dates";

export const Header = () => {
  const [start, end] = currentWeek();

  return (
    <div className={classes.container}>
      <Link to="/history" className={classes.button} title="History">
        <MdMenu />
      </Link>
      <h1>
        <Link to="/">
          {start}&nbsp;&ndash;&nbsp;{end}
        </Link>
      </h1>
      <Link to="/help" className={classes.button} title="Help">
        <MdHelpOutline />
      </Link>
    </div>
  );
};
