import React, { Suspense } from "react";
import classes from "./Page.module.css";
import { Header } from "./Header";
import { Loading } from "components/Loading";

type Props = {
  children: React.ReactNode;
};

export const Page = ({ children }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.pageContent}>
        <Header />
        <div className={classes.body}>
          <Suspense fallback={<Loading message="Loading ..." />}>
            <div className={classes.content}>{children}</div>
          </Suspense>
        </div>
      </div>
    </div>
  );
};
