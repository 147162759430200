import type { Unsubscribe } from "firebase/database";
import { get, onValue, set } from "firebase/database";
import { PROJECT_ID, YEAR_KEY } from "utils/constants";
import type { AnswerType, QuestionType } from "utils/types";
import { Answer } from "utils/types";
import { path } from "./firebase";

export const loadPublishedAnswers = async (
  questionId: string
): Promise<string[]> => {
  return get(path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}`)).then(
    (snap) => {
      if (!snap.exists()) {
        return [];
      }
      return Object.keys(snap.val());
    }
  );
};

export const onPublishedAnswersChange = (
  questionId: QuestionType["id"],
  callback: (uids: string[]) => void
): Unsubscribe => {
  return onValue(
    path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}`),
    (snap) => {
      if (!snap.exists()) {
        callback([]);
        return;
      }
      callback(Object.keys(snap.val()));
    }
  );
};

export const loadAnswerPublishedState = async (
  uid: string,
  questionId: string
): Promise<"published" | "unpublished"> => {
  return get(
    path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}/${uid}`)
  ).then((snap) => (snap.exists() ? "published" : "unpublished"));
};

export const onPublishedStateChange = (
  uid: string,
  questionId: string,
  callback: (state: "published" | "unpublished") => void
): Unsubscribe => {
  return onValue(
    path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}/${uid}`),
    (snap) => callback(snap.exists() ? "published" : "unpublished")
  );
};

export const publishAnswer = async (uid: string, questionId: string) => {
  return set(
    path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}/${uid}`),
    Date.now()
  );
};

export const unpublishAnswer = async (uid: string, questionId: string) => {
  return set(
    path(`/published/${PROJECT_ID}/${YEAR_KEY}/${questionId}/${uid}`),
    null
  );
};

export const loadPublishedAnswer = async (
  questionId: QuestionType["id"],
  otherUid: string
): Promise<AnswerType> => {
  return get(
    path(`/users/${otherUid}/${PROJECT_ID}/${YEAR_KEY}/answers/${questionId}`)
  ).then((snap) => {
    if (!snap.exists()) {
      throw new Error("Answer not published");
    }
    const answer = Answer(snap.val());
    if (answer.type !== "success") {
      throw new Error(`Failed to validate Answer object`);
    }
    return answer.value;
  });
};
