import {
  get,
  set,
  DataSnapshot,
  onValue,
  onChildChanged,
} from "@firebase/database";
import { captureMessage } from "@sentry/react";
import { PROJECT_ID } from "utils/constants";
import { unsynced } from "utils/storage";
import type {
  AnswerType,
  AnswersType,
  QuestionType} from "utils/types";
import {
  Answer,
  Answers
} from "utils/types";
import { path } from "./firebase";
import { isOffline } from "utils/offline";

type AnswerArgs =
  | { key: string }
  | { uid: string; yearKey: string; questionId: string };

export const getAnswerKey = (args: AnswerArgs) => {
  if ("key" in args) {
    return args.key;
  }
  return `/users/${args.uid}/${PROJECT_ID}/${args.yearKey}/answers/${args.questionId}`;
};

export const validateAnswer = (obj: DataSnapshot | unknown): AnswerType => {
  if (obj instanceof DataSnapshot) {
    if (!obj.exists()) {
      return { items: {}, order: [] };
    }

    return validateAnswer({ content: [], ...obj.val() });
  }

  if (!obj) {
    return { items: {}, order: [] };
  }

  if (typeof obj !== "object") {
    throw new Error("Answer object is not an object");
  }

  const answer = Answer(obj);
  if (answer.type !== "success") {
    throw new Error(`Failed to validate Answer object`);
  }
  return answer.value;
};

export const loadAnswer = async (
  args: AnswerArgs,
  offlineValue: AnswerType | null
) => {
  if (isOffline()) {
    return offlineValue;
  }
  return get(path(getAnswerKey(args))).then((snap) => validateAnswer(snap));
};

export const onAnswerChanged = (
  args: AnswerArgs,
  callback: (updatedAnswer: AnswerType) => void
) => {
  return onValue(path(getAnswerKey(args)), (snap) => {
    callback(validateAnswer(snap));
  });
};

export const updateAnswer = async (
  args: AnswerArgs,
  answer: AnswerType,
  question: QuestionType["question"]
) => {
  const key = getAnswerKey(args);
  const firebasePath = path(key);
  return unsynced.transact<AnswerType>(
    set(firebasePath, answer).then(() => answer),
    {
      localforageKey: key,
      lastModifiedPath: key,
      firebasePath: key,
      timestamp: Date.now(),
      message: `Answer for '${question}'`,
    },
    answer
  );
};

type AnswersArgs = { key: string } | { uid: string; yearKey: string };

export const getAnswersKey = (args: AnswersArgs) => {
  if ("key" in args) {
    return args.key;
  }
  return `/users/${args.uid}/${PROJECT_ID}/${args.yearKey}/answers`;
};

export const validateAnswers = (obj: DataSnapshot | unknown): AnswersType => {
  if (obj instanceof DataSnapshot) {
    if (!obj.exists()) {
      return {};
    }

    return validateAnswers(obj.val());
  }
  if (typeof obj !== "object") {
    throw new Error("Answers object is not an object");
  }

  if (obj === null) {
    return {};
  }

  const answers = Answers(obj);
  if (answers.type !== "success") {
    captureMessage(`Failed to validate Answers object: ${JSON.stringify(obj)}`);
    throw new Error(
      `Failed to validate Answers object: ${answers.message} (${answers.path})`
    );
  }
  return answers.value;
};

export const onAnswersChildChanged = (
  args: AnswersArgs,
  callback: (updatedAnswer: AnswerType, key: string | null) => void
) => {
  return onChildChanged(path(getAnswersKey(args)), (snap) => {
    callback(validateAnswer(snap), snap.key);
  });
};

export const loadAnswers = (args: AnswersArgs, defaultValue: AnswersType) => {
  if (isOffline()) {
    return defaultValue;
  }
  return get(path(getAnswersKey(args))).then((snap) => validateAnswers(snap));
};
