import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilValue } from "recoil";
import { YEAR_KEY } from "utils/constants";
import { getAnswerKey, updateAnswer } from "utils/firebase";
import type { AnswerType, QuestionType } from "utils/types";
import { useUid } from "./uid";
import { store } from "utils/storage";
import { isOffline, useOffline } from "utils/offline";
import { captureException } from "@sentry/react";
import { useQuestion } from "./sheets";
import {
  loadAnswerPublishedState,
  onPublishedStateChange,
} from "utils/firebase/published";

export const answersState = atom<Record<string, AnswerType>>({
  key: "answersState",
  default: {},
});

export const useAnswer = (questionId: QuestionType["id"]) => {
  const uid = useUid();
  const answer = useRecoilValue(answersState)[questionId];
  const [saving, setSaving] = useState(false);
  const key = getAnswerKey({ uid, yearKey: YEAR_KEY, questionId });
  const { question } = useQuestion(questionId) ?? { question: questionId };

  const saveAnswer = useCallback(
    async (answer: AnswerType) => {
      if (!answer) {
        return null;
      }

      if (!question) {
        return null;
      }

      setSaving(true);
      try {
        const savedAnswer = await store.setItem(key, answer);
        if (!isOffline()) {
          await updateAnswer({ key }, savedAnswer, question);
        }
        return savedAnswer;
      } catch (ex) {
        captureException(ex);
      } finally {
        setSaving(false);
      }
    },
    [key, question]
  );
  return {
    answer,
    save: saveAnswer,
    saving,
  };
};

export const useAnsweredQuestionIds = (): QuestionType["id"][] => {
  const value = useRecoilValue(answersState);
  return Object.keys(value);
};

export const usePublishedState = (
  questionId: string
): "published" | "unpublished" => {
  const uid = useUid();
  const { offline } = useOffline();

  const [state, setState] =
    useState<"published" | "unpublished">("unpublished");

  useEffect(() => {
    if (offline) {
      return;
    }

    loadAnswerPublishedState(uid, questionId).then((state) => setState(state));
    return onPublishedStateChange(uid, questionId, (state) => setState(state));
  }, [offline, questionId, uid]);

  return state;
};
