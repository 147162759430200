import { Helmet } from "react-helmet";
import { MdRemoveCircleOutline, MdSnooze } from "react-icons/md";
import classes from "./Help.module.css";

const HEX: Record<string, true> = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
].reduce((acc, v) => ({ ...acc, [v]: true }), {});

const Version = () => {
  const version = String(
    process.env.REACT_APP_RELEASE ?? "development"
  ).substring(0, 6);

  const hex = version
    .split("")
    .map((c) => {
      return HEX[c.toLowerCase()] ? c : "0";
    })
    .join("");

  return (
    <div className={classes.container}>
      <em>Version: </em>
      <a href={window.location.href}>
        <div
          className={classes.version}
          style={{
            color: `#${hex}`,
            backgroundColor: `#${hex}`,
          }}
        >
          {version}
        </div>
      </a>
    </div>
  );
};

export const Help = () => (
  <>
    <Helmet>
      <title>Atlantis - Help</title>
    </Helmet>
    <div>
      <p>
        This is the app to progressively present questions to answer. This is
        intended to be fun & thoughtful, not stressful. So please have fun with
        it!
      </p>
      <hr />
      <strong>&quot;What's this question?&quot;</strong>
      <p>
        There is a new question each week. If that question isn't to your
        liking, you can either snooze it (<MdSnooze />) so you'll be asked
        later, or remove it entirely (<MdRemoveCircleOutline />) to stop being
        asked about it.
      </p>
      <hr />
      <strong>&quot;What other questions are there?&quot;</strong>
      <p>You'll have to wait and find out. Patience is a virtue 😊.</p>
      <hr />
      <strong>&quot;What happens at the end of the year?&quot;</strong>
      <p>I don't know! We'll have to find out together.</p>
      <hr />
      <strong>&quot;I'm stuck!&quot;</strong>
      <p>
        That's okay&mdash;help is only a message away! Just get ahold of Evan
        and he can help you sort things out.
      </p>
      <hr />
      <Version />
    </div>
  </>
);
