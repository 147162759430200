import React, { createContext, useContext, useMemo, useState } from "react";
import classes from "./Modal.module.css";

type Props = {
  children: JSX.Element;
};

type ModalButton = {
  text: string;
  onClick: () => void;
};

type ModalData = {
  title: string;
  content: React.ReactNode;
  buttons?: ModalButton[];
};

const ModalContext = createContext<(info: ModalData | null) => void>(() => {
  throw new Error("Rendered outside of a context");
});

export const useModal = () => {
  const modify = useContext(ModalContext);

  return {
    open: (info: ModalData) => modify(info),
    close: () => modify(null),
  };
};

export const Modal = ({ children }: Props) => {
  const [modalInfo, setModalInfo] = useState<ModalData | null>(null);

  const modal = useMemo(() => {
    if (!modalInfo) {
      return null;
    }

    const { title, content, buttons = [] } = modalInfo;

    let buttonFooter = null;
    if (buttons.length > 0) {
      const buttonElements = buttons.map(({ text, onClick }) => (
        <button key={text} onClick={onClick}>
          {text}
        </button>
      ));
      buttonFooter = <div className={classes.buttons}>{buttonElements}</div>;
    }

    return (
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.title}>{title}</div>
          <div className={classes.content}>{content}</div>
          {buttonFooter}
        </div>
      </div>
    );
  }, [modalInfo]);

  return (
    <ModalContext.Provider value={setModalInfo}>
      {children}
      {modal}
    </ModalContext.Provider>
  );
};
