import localforage from "localforage";
import type {
  DirtyRecordType2} from "utils/firebase/offline";
import {
  DirtyRecord2,
  isDirtyFirebaseRecord,
} from "utils/firebase/offline";
import type {
  SheetsBackfillType,
  SheetsQuestionsType,
  SheetsQueueType,
  UserType,
} from "utils/types";
import sha1 from "sha1";
import { set } from "firebase/database";
import { path } from "utils/firebase/firebase";
import { isOffline } from "utils/offline";
import { captureMessage } from "@sentry/react";

const VERSION = 1;

export const userData = (() => {
  const instance = localforage.createInstance({
    name: `atlantis/v${VERSION}`,
    version: VERSION,
    storeName: "user",
  });
  return {
    user: (value?: UserType) => {
      if (value) {
        return instance.setItem("user", value);
      }
      return instance.getItem<UserType>("user");
    },
    uid: (value?: string) => {
      if (value) {
        return instance.setItem("uid", value);
      }
      return instance.getItem<string>("uid") ?? "";
    },
    email: (value?: string) => {
      if (value) {
        return instance.setItem("email", value);
      }
      return instance.getItem<string>("email") ?? "";
    },
  };
})();

export const questions = (() => {
  const instance = localforage.createInstance({
    name: `atlantis/v${VERSION}`,
    version: VERSION,
    storeName: "questions",
  });

  return {
    queue: (value?: SheetsQueueType) => {
      if (value) {
        return instance.setItem<SheetsQueueType>("queue", value);
      }
      return instance.getItem<SheetsQueueType>("queue");
    },
    pool: (value?: SheetsQuestionsType) => {
      if (value) {
        return instance.setItem<SheetsQuestionsType>("pool", value);
      }
      return instance.getItem<SheetsQuestionsType>("pool");
    },
    backfill: (value?: SheetsBackfillType) => {
      if (value) {
        return instance.setItem<SheetsBackfillType>("backfill", value);
      }
      return instance.getItem<SheetsBackfillType>("backfill");
    },
  };
})();

export const unsynced = (() => {
  const lastModifiedRef = (key: string) => path(`/lastModified/${key}`);

  const instance = localforage.createInstance({
    name: `atlantis/v${VERSION}`,
    version: VERSION,
    storeName: "unsynced",
  });

  return {
    transact: async <T extends unknown>(
      operation: Promise<T>,
      record: DirtyRecordType2,
      offlineResult: T
    ) => {
      const dirtyPath = sha1(record.localforageKey);
      await instance.setItem(dirtyPath, record);
      if (isOffline()) {
        return offlineResult;
      }

      const result = await operation;
      if (isDirtyFirebaseRecord(record)) {
        await set(lastModifiedRef(record.lastModifiedPath), Date.now());
      }
      await instance.removeItem(dirtyPath);
      return result;
    },

    pendingKeys: () => instance.keys(),

    getDirtyRecord: async (key: string) => {
      const unvalidated = await instance.getItem<DirtyRecordType2>(key);
      const validated = DirtyRecord2(unvalidated);
      if (validated.type !== "success") {
        captureMessage("Incorrect dirty record", {
          extra: { key, unvalidated },
        });
        await instance.removeItem(key);
        return null;
      }
      return validated.value;
    },

    clear: (keys?: string[]) => {
      if (!keys) {
        return instance.clear();
      }
      return Promise.all(keys.map((key) => instance.removeItem(key)));
    },
  };
})();

export const store = (() => {
  return localforage.createInstance({
    name: `atlantis/v${VERSION}`,
    version: VERSION,
    storeName: "atlantis",
  });
})();
